import { useMutation } from '@apollo/react-hooks'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { navigate } from '@reach/router'
import { gql } from 'apollo-boost'
import { Page } from 'components'
import ContactRole from 'components/ContactRole'
import { Field, Formik } from 'formik'
import React from 'react'
import constants from 'utils/constants'
import { array, mixed, number, object, string } from 'yup'
import SchoolAndParticipants from './SchoolAndParticipants'
import SchoolEnrollmentContacts from './SchoolEnrollmentContacts'
import SchoolSearch from './SchoolSearch'

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(6, 2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  intro: {
    marginBottom: theme.spacing(4),
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  card: {
    // width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.sm,
    marginBottom: theme.spacing(3),
  },
  innerCard: {
    paddingTop: 0,
  },
  checkboxWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  inputField: {
    position: 'relative',
    width: '100%',
  },
  contactHeader: {
    marginTop: theme.spacing(3),
  },
  navigation: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    padding: theme.spacing(4),
  },
  cardActions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  option: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    maxWidth: 560,
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  optionDetails: {
    marginLeft: theme.spacing(2),
  },
}))

const CREATE_ENROLLMENT = gql`
  mutation ($input: EnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

function getSteps() {
  return ['Skole', 'Kontaktpersoner', 'Bekreft']
}

let ValidationSchema = object().shape({
  school: string().required('Du må søke og velge din skole'),
  numberOfStudents: number()
    .typeError('Du skrive inn antall elever')
    .required('Du skrive inn antall elever'),
  numberOfTeachers: number()
    .typeError('Du må skrive inn antall lærere')
    .required('Du må skrive inn antall lærere'),
  contacts: array()
    .of(
      object().shape({
        firstName: string().required('Du må fylle inn fornavn'),
        lastName: string().required('Du må fylle inn etternavn'),
        email: string().email('Ugyldig e-postadresse').required('Du må fylle inn e-post'),
        phoneNumber: string().required('Du må fylle inn telefonnummer'),
        type: mixed()
          .oneOf(['disabled', 'student', 'teacher'], 'Du må velge rolle')
          .required('Du må velge rolle'),
      })
    )
    .required('Du må fylle inn kontaktperson')
    .min(1, 'Du må fylle inn minst én kontaktperson'), // these constraints take precedence
  enrollmentType: string().oneOf(['odDay', 'iu', 'odSchool']).required('Du må velge én'),
})

const EnrollmentSignup = (props) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [activeEnrollments, setActiveEnrollments] = React.useState([])

  // const [searchString, setSearchString] = React.useState('')
  const [createEnrollment] = useMutation(CREATE_ENROLLMENT)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleActiveEnrollments = (enrollments) => {
    setActiveEnrollments(enrollments)
  }

  const initialValues = {
    school: null,
    odSchool: false,
    odDay: false,
    iu: false,
    enrollmentType: '',
    numberOfStudents: '',
    numberOfTeachers: '',
    contacts: [
      {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        type: 'teacher',
        title: '',
      },
    ],
  }

  return (
    <Page className={classes.root} title="Påmelding">
      <Formik
        isInitialValid={false}
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            await createEnrollment({
              variables: {
                input: {
                  schoolName: values.school.name,
                  school: values.school.id,
                  contacts: values.contacts,
                  estStudentCount: values.numberOfStudents,
                  estTeacherCount: values.numberOfTeachers,
                  comments: values.comments,
                  odSchool: values.enrollmentType === 'odSchool',
                  types: [
                    values.enrollmentType === 'odDay' && constants.enrollmentTypes.odDay,
                    values.enrollmentType === 'iu' && constants.enrollmentTypes.iu,
                    values.enrollmentType === 'odSchool' && constants.enrollmentTypes.odSchool,
                  ].filter(Boolean),
                },
              },
            })
            actions.setSubmitting(false)
            navigate('/success')
          } catch (error) {
            actions.setSubmitting(false)
            actions.setStatus(error.message || 'Ukjent feil.')
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Card className={classes.card}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              <Divider />

              <CardContent className={classes.content}>
                <Typography align="center" component="h1" variant="h2" className={classes.heading}>
                  Delta på OD 2025!
                </Typography>

                {activeStep === 0 && !props.values.school && <SchoolSearch {...props} />}
                {activeStep === 0 && props.values.school && (
                  <SchoolAndParticipants
                    handleActiveEnrollments={handleActiveEnrollments}
                    {...props}
                  />
                )}
                {activeStep === 1 && <SchoolEnrollmentContacts {...props} />}
                {activeStep === 2 && (
                  <>
                    <Box mb={4}>
                      <Typography align="center" component="p" gutterBottom variant="h5">
                        Ved å melde på skolen bestiller dere automatisk materiell som blir tilsendt
                        skolen i starten av september. Informasjon om praktisk gjennomføring,
                        foredrag og digitale undervisningsressurser finnes på{' '}
                        <Link href="https://www.od.no" target="_blank" rel="noopener noreferrer">
                          våre nettsider.
                        </Link>
                      </Typography>
                    </Box>
                    <Card className={classes.card}>
                      <CardHeader title="Skole" />
                      <Divider />
                      <CardContent className={classes.innerCard}>
                        <List>
                          {props.values.school && (
                            <ListItem className={classes.listItem} disableGutters divider>
                              <Typography variant="subtitle2">Navn</Typography>
                              <Typography variant="h6">{props.values.school.name}</Typography>
                            </ListItem>
                          )}
                          {props.values.numberOfStudents && (
                            <ListItem className={classes.listItem} disableGutters divider>
                              <Typography variant="subtitle2">Antall elever</Typography>
                              <Typography variant="h6">{props.values.numberOfStudents}</Typography>
                            </ListItem>
                          )}
                          {props.values.numberOfTeachers && (
                            <ListItem className={classes.listItem} disableGutters divider>
                              <Typography variant="subtitle2">Antall lærere</Typography>
                              <Typography variant="h6">{props.values.numberOfTeachers}</Typography>
                            </ListItem>
                          )}
                        </List>
                      </CardContent>
                    </Card>
                    {props.values.contacts && (
                      <Card className={classes.card}>
                        <CardHeader title="Kontaktpersoner" />
                        <Divider />
                        <CardContent className={classes.innerCard}>
                          <List>
                            {props.values.contacts.map((contact) => (
                              <React.Fragment key={contact.email}>
                                <ListItem className={classes.listItem} disableGutters divider>
                                  <Typography variant="subtitle2">
                                    {contact.firstName} {contact.lastName}
                                  </Typography>
                                  <Typography variant="h6">
                                    <ContactRole>{contact.type}</ContactRole>
                                  </Typography>
                                </ListItem>
                                <ListItem className={classes.listItem} disableGutters divider>
                                  <Typography variant="subtitle2">E-post:</Typography>
                                  <Typography variant="h6">{contact.email}</Typography>
                                </ListItem>
                                <ListItem className={classes.listItem} disableGutters divider>
                                  <Typography variant="subtitle2">Telefon:</Typography>
                                  <Typography variant="h6">{contact.phoneNumber}</Typography>
                                </ListItem>
                              </React.Fragment>
                            ))}
                          </List>
                        </CardContent>
                      </Card>
                    )}
                    <Box mt={3} mb={4}>
                      <Field
                        name={`comments`}
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        label="Kommentarer"
                        type="text"
                        multiline
                        rows="4"
                        helperText="Skriv inn kommentarer, ønsker eller spesielle behov dersom det er aktuelt."
                      />
                    </Box>

                    <FormControl component="fieldset" className={classes.formControl}>
                      <RadioGroup aria-label="enrollmentType" name="enrollmentType">
                        <label htmlFor="odSchool" className={classes.option}>
                          <Field
                            id="odSchool"
                            name="enrollmentType"
                            value="odSchool"
                            color="primary"
                            as={Radio}
                          />
                          <div className={classes.optionDetails}>
                            <Typography gutterBottom variant="h5">
                              Vi ønsker å bli en OD-skole
                            </Typography>
                            <Typography variant="body1">
                              Ved å bli en OD-skole, vil skolen melde seg på årets OD, og automatisk
                              være påmeldt OD fremover til skolen melder seg av.
                            </Typography>
                          </div>
                        </label>

                        <label htmlFor="odDay" className={classes.option}>
                          <Field
                            id="odDay"
                            name="enrollmentType"
                            value="odDay"
                            color="primary"
                            as={Radio}
                          />
                          <div className={classes.optionDetails}>
                            <Typography gutterBottom variant="h5">
                              Vi ønsker å delta på årets OD
                            </Typography>
                            <Typography variant="body1">
                              Ved å delta på årets OD vil skolen gjennomføre Internasjonal Uke på
                              sin skole, og elevene jobber på OD-dagen.
                            </Typography>
                          </div>
                        </label>

                        <label htmlFor="iu" className={classes.option}>
                          <Field
                            id="iu"
                            name="enrollmentType"
                            value="iu"
                            color="primary"
                            as={Radio}
                          />
                          <div className={classes.optionDetails}>
                            <Typography gutterBottom variant="h5">
                              Vi ønsker kun å delta på årets Internasjonale Uke
                            </Typography>
                            <Typography variant="body1">
                              Ved å kun delta på Internasjonal Uke vil skolen få tilsendt materiell
                              og bli tilbudt foredrag, men elevene jobber ikke på OD-dagen.
                            </Typography>
                          </div>
                        </label>
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
                <div className={classes.navigation}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Tilbake
                  </Button>

                  {activeStep === 0 && (
                    <Button
                      disabled={!props.values.school || activeEnrollments.length > 0}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Neste
                    </Button>
                  )}
                  {activeStep === 1 && (
                    <Button
                      disabled={props.errors.contacts}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Neste
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!props.isValid}
                      startIcon={
                        props.isSubmitting && <CircularProgress color="inherit" size={18} />
                      }
                    >
                      {props.isSubmitting ? 'Sender inn...' : 'Send inn'}
                    </Button>
                  )}
                </div>
                {!!props.status && <Typography color="error">{props.status}</Typography>}
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Page>
  )
}

export default EnrollmentSignup
